<template>
    <div>
        <el-row :gutter="15">
            <el-col :span="8">
                <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                    <p class="text-lg mb-4 font-semibold">
                        {{ totalHours }}
                    </p>
                    <p>
                        {{ $t('salaries.total_worked_hours') }}
                    </p>
                </div>
            </el-col>
            <el-col :span="8">
                <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                    <p class="text-lg mb-4 font-semibold">
                        {{ totalPlannedHours }}
                    </p>
                    <p>
                        {{ $t('salaries.total_planned_hours') }}
                    </p>
                </div>
            </el-col>
            <el-col :span="8">
                <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
                    <p class="text-lg mb-4 font-semibold">
                        {{ intToMoneyFormat(salariesTotal) }}
                    </p>
                    <p>
                        {{ $t('salaries.salaries_total') }}
                    </p>
                </div>
            </el-col>
        </el-row>

        <div class="card main-card flex flex-col">
            <div class="flex justify-between">
                <div class="select">
                    <el-select v-model="selectedEmployee" :placeholder="$t('salaries.employees')" filterable clearable @change="selectEmployee">
                        <el-option
                            v-for="employee in employeesData"
                            :key="employee.uuid"
                            :label="employee.name + ' ' + employee.surname"
                            :value="employee.uuid"
                        />
                    </el-select>
                </div>
                <div class="menu flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('salaries.export_to_pdf')" placement="top">
                        <el-button type="primary" size="mini" :disabled="salariesData.length == 0" @click="toPdf(false)">
                            <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('salaries.print')" placement="top">
                        <el-button type="primary" size="mini" :disabled="salariesData.length == 0" class="btn-print" @click="toPdf(true)">
                            <fa-icon :icon="['fas', 'print']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <DateRangePicker @change="getAll" />
                </div>
            </div>
            <hr class="my-4">
            <el-table v-if="salariesData.length > 0" :data="salariesData" class="w-full">
                <el-table-column :label="$t('salaries.employee')" prop="employee" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{ scope.row.employee_name }} {{ scope.row.employee_surname }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('salaries.worked_minutes')" prop="duration" width="120">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.worked_minutes) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('salaries.planned_time')" prop="planned" width="120">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.planned_minutes) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('salaries.salary')" prop="salary" width="120">
                    <template slot-scope="scope">
                        {{ intToMoneyFormat(scope.row.salary) }}
                    </template>
                </el-table-column>
                <el-table-column width="75">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('salaries.details')" placement="left">
                                <router-link :to="`/salaries/${scope.row.uuid}`" class="el-button el-tooltip el-button--primary el-button--mini">
                                    <fa-icon :icon="['fas', 'info']" fixed-width />
                                </router-link>
                            </el-tooltip>
                            <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('salaries.delete')" placement="top">
                                <el-button type="danger" size="mini" :disabled="!userCan('delete salaries')" @click="handleDelete(scope.row.uuid)">
                                    <fa-icon :icon="['fas', 'trash']" fixed-width />
                                </el-button>
                            </el-tooltip>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <InitialLoader v-if="$wait.is('loading.initial_*')" />

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import sumBy from 'lodash/sumBy';

import queryString from 'query-string';

export default {
    components: {
        DateRangePicker: () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
    },

    data() {
        return {
            noDataInformation: false,
            selectedEmployee:  null,
            salariesData:      [],
            employeesData:     [],
        };
    },

    computed: {
        totalHours() {
            return this.normalizeTime(sumBy(this.salariesData, 'worked_minutes'));
        },

        totalPlannedHours() {
            return this.normalizeTime(sumBy(this.salariesData, 'planned_minutes'));
        },

        salariesTotal() {
            return String(sumBy(this.salariesData, 'salary')).replace('.', ',');
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_salaries');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('economy.salaries'));
        this.$store.commit('setActiveMenuItem', '6-2');

        this.getEmployees();
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const query = queryString.parse(window.location.search, { sort: false });

            this.selectedEmployee = query.employee;

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/salaries`, {
                    params: {
                        employee: query.employee,
                        from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                        to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    },
                });
                this.salariesData = Object.freeze(data);
                this.noDataInformation = data.length;
                this.$wait.end('loading');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
                this.$wait.end('loading.initial_salaries');
            } catch (error) {
                this.$wait.end('loading');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
                this.$wait.end('loading.initial_salaries');
            }
        },

        handleDelete(uuid) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/salaries/${uuid}`);
                            this.$notify.success({
                                title: 'Success',
                            });
                            this.getAll();
                        } catch {
                            return;
                        }

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },

        async getEmployees() {
            this.$wait.start('loading.employees');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.employeesData = employees.data;

            this.$wait.end('loading.employees');
        },

        selectEmployee() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.employee = this.selectedEmployee;

            this.$router.replace({
                query,
            }).catch(() => {});

            this.getAll();
        },

        async toPdf(toPrint = false) {
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/salaries/pdf_sign_route`, {
                params: {
                    employee: query.employee,
                    from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    print:    toPrint,
                },
            });
            window.open(data);
        },
    },
};
</script>
<style scoped>
.btn-print {
    margin-right: 10px !important;
}

.menu .el-button--mini {
    padding: 7px !important;
}

.main-card {
    min-height: calc(100vh - 200px);
}

</style>
